import React, { useState } from "react";
import youngResearchersAwardPDF from "./../../assets/young-researchers-award.pdf";
import youngResearchersAward from "./../../assets/young-researchers-award.jpeg";
import epaperAwardDoctors from "./../../assets/best-e-paper-award-doctors.jpeg";
import paperAwardNurses from "./../../assets/best-paper-award-nurses.jpeg";

const AwardsSection = () => {
    const [modalVisible, setModalVisible] = useState(false);
    const [modalImage, setModalImage] = useState("");
    const [modalCaption, setModalCaption] = useState("");

    const awards = [
        {
            name: "Young Researchers Award",
            image: youngResearchersAward,
            pdf: youngResearchersAwardPDF,
        },
        {
            name: "Best Free E-Paper for Doctors",
            image: epaperAwardDoctors,
        },
        {
            name: "Best Free Paper for Nurses",
            image: paperAwardNurses,
        },
    ];

    const openModal = (award) => {
        if (award.name === "Young Researchers Award" && award.pdf) {
            window.open(award.pdf, "_blank"); // Open the PDF for "Young Researchers Award"
        } else {
            setModalImage(award.image);
            setModalCaption(award.name);
            setModalVisible(true);
        }
    };

    const closeModal = (e) => {
        e.stopPropagation();
        setModalVisible(false);
        setModalImage("");
        setModalCaption("");
    };

    const navigateToAbstract = () => {
        window.open("/#/registration/abstract", "_blank");
    };



    return (
        <div className="awards-section" id="regawards">
            <div className="awards-banner">
                <div className="reg-heading orbitron-font-set">🏆 Registration for Awards 🏆</div>
                <div className="awards-tags">
                    {awards.map((award, index) => (
                        <div
                            key={index}
                            className="award-card"
                            onClick={() => openModal(award)}
                        >
                            <img
                                src={award.image}
                                alt={award.name}
                                className="award-thumbnail"
                            />
                            <p>{award.name}</p>
                        </div>
                    ))}
                </div>
                <button className="submit-btn" onClick={navigateToAbstract}>
                    Submit Abstracts
                </button>
            </div>

            {/* Image Modal */}
            {
                modalVisible && (
                    <div className="modal-overlay" onClick={closeModal}>
                        <div
                            className="modal-image-content"
                            onClick={(e) => e.stopPropagation()}
                        >
                            <div className="modal-close" onClick={closeModal}>
                                &times;
                            </div>
                            <img
                                src={modalImage}
                                alt={modalCaption}
                                className="modal-image"
                            />
                            <p className="modal-caption">{modalCaption}</p>
                        </div>
                    </div>
                )
            }
        </div >
    );
};

export default AwardsSection;