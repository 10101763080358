import Contact from "./components/Contact/Contact";
import Hero from "./components/Hero/Hero";
import Navbar from "./components/Navbar/Navbar";
import Organizer from "./components/Organizer/Organizer";
import Registration from "./components/Registration/Registration";
import RegisterAwards from "./components/RegisterAwards/RegisterAwards";
import AbstractRegistration from "./components/AbstractRegistration/AbstractRegistration";
import ColorTabs from "./components/Schedule/Schedule";
import Venue from "./components/Venue/Venue";
import Accommodation from "./components/Accommodation/Accommodation";
import { HashRouter as Router, Routes, Route } from "react-router-dom";

function App() {
  return (
    <div className="App">
      <Router>
        <Routes>
          <Route
            path="/"
            element={
              <>
                <Navbar />
                <Hero />
                <Registration />
                <RegisterAwards />
                <Accommodation />
                <ColorTabs />
                <Organizer />
                <Venue />
                <Contact />
              </>
            }
          />
          <Route path="/registration/Abstract"
          element={
              <>
                <AbstractRegistration />
              </>
            }
          />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
