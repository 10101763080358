import React, { useState, useEffect } from "react";

function Navbar({ isMainWebsite = true }) {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const scrollToSection = (id) => {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  };

  useEffect(() => {
    // Scroll to the section if there's a hash in the URL
    const hash = window.location.hash;
    if (hash) {
      const id = hash.replace("#/#", ""); // Remove "#/"
      scrollToSection(id);
    }

    const handleHashChange = () => {
      const hash = window.location.hash;
      if (hash) {
        const id = hash.replace("#/#", "");
        scrollToSection(id);
      }
    };

    // Listen for hash changes
    window.addEventListener("hashchange", handleHashChange);

    return () => {
      window.removeEventListener("hashchange", handleHashChange);
    };
  }, []);

  const handleScroll = (e, id) => {
    e.preventDefault();
    if (!isMainWebsite) {
      // Redirect to the main website with the section ID
      window.location.href = `/#/#${id}`;
    } else {
      scrollToSection(id);
      window.history.pushState(null, "", `/#/#${id}`); // Update the hash in the URL
    }
    setIsMenuOpen(false); // Close the menu after clicking
  };

  return (
    <div className="navbar">
      <div className="navbar-header">
        <button className="hamburger-menu" onClick={toggleMenu}>
          ☰
        </button>
      </div>
      <ul className={`navbar-content ${isMenuOpen ? "open" : ""}`}>
        <li>
          <a href="#/about" onClick={(e) => handleScroll(e, "about")}>About</a>
        </li>
        <li>
          <a href="#/reg" onClick={(e) => handleScroll(e, "reg")}>Registration</a>
        </li>
        <li>
          <a href="#/regawards" onClick={(e) => handleScroll(e, "regawards")}>
            Register for awards
          </a>
        </li>
        <li>
          <a href="#/accommodation" onClick={(e) => handleScroll(e, "accommodation")}>
            Accommodation
          </a>
        </li>
        <li>
          <a href="#/schedule" onClick={(e) => handleScroll(e, "schedule")}>Schedule</a>
        </li>
        <li>
          <a href="#/organizers" onClick={(e) => handleScroll(e, "organizers")}>Organizers</a>
        </li>
        <li>
          <a href="#/venue" onClick={(e) => handleScroll(e, "venue")}>Venue</a>
        </li>
        <li>
          <a href="#/contact" onClick={(e) => handleScroll(e, "contact")}>Contact Us</a>
        </li>
      </ul>
    </div>
  );
}

export default Navbar;
