import React, { useState } from "react";
import emailjs from "emailjs-com";
import Navbar from "../Navbar/Navbar";
import Contact from "../Contact/Contact";

const AbstractForm = () => {
    const [formErrors, setFormErrors] = useState({});
    const [wordCount, setWordCount] = useState(0); // Track the current word count
    const [abstractBody, setAbstractBody] = useState(""); // Track the textarea content
    const [isSubmitting, setIsSubmitting] = useState(false); // Prevent duplicate submissions

    const validateForm = (formData) => {
        const errors = {};
        if (!formData.registrationNumber.trim()) {
            errors.registrationNumber = "Registration number is required.";
        }
        if (!formData.authorName.trim()) {
            errors.authorName = "Author's name is required.";
        }
        if (!formData.phoneNumber.trim()) {
            errors.phoneNumber = "Phone number is required.";
        } else if (!/^[0-9]{10}$/.test(formData.phoneNumber)) {
            errors.phoneNumber = "Please enter a valid 10-digit phone number.";
        }
        if (!formData.category.trim()) {
            errors.category = "Please select a category.";
        }
        if (!formData.abstractTitle.trim()) {
            errors.abstractTitle = "Abstract title is required.";
        }
        const words = formData.abstractBody.trim().split(/\s+/); // Count words
        if (words.length > 200) {
            errors.abstractBody = "Abstract body cannot exceed 200 words.";
        }
        if (words.length === 0 || !formData.abstractBody.trim()) {
            errors.abstractBody = "Abstract body is required.";
        }
        return errors;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (isSubmitting) return; // Prevent duplicate submissions

        const formData = Object.fromEntries(new FormData(e.target).entries());
        const errors = validateForm(formData);

        if (Object.keys(errors).length > 0) {
            setFormErrors(errors);
            return;
        }

        setFormErrors({});
        setIsSubmitting(true); // Lock form while submitting

        try {
            await emailjs.sendForm(
                "service_mlc0pia",
                "template_rblbeul",
                e.target,
                "UAVl6frM2JGRB2eVF"
            );
            alert("Abstract submitted successfully!");
            e.target.reset();
            setAbstractBody(""); // Clear textarea
            setWordCount(0); // Reset word count
        } catch (error) {
            console.error("Error submitting abstract:", error);
            alert("An error occurred while submitting the abstract.");
        } finally {
            setIsSubmitting(false); // Unlock form after submission attempt
        }
    };

    const handleWordCount = (e) => {
        const text = e.target.value;
        const words = text.trim().split(/\s+/);
        if (words.length <= 200) {
            setAbstractBody(text); // Update the textarea content
            setWordCount(words.length > 1 || words[0] !== "" ? words.length : 0);
        }
    };

    return (
        <>
            <Navbar isMainWebsite={false} />
            <div className="abstract-container">
                <h2 className="form-heading">VAICON 2025 Abstract Submission</h2>
                <form onSubmit={handleSubmit} className="abstract-form">
                    <div className="form-row">
                        <div className="form-group">
                            <label>
                                Provisional Registration Number *
                                <input
                                    type="text"
                                    name="registrationNumber"
                                    placeholder="Enter Provisional Registration No"
                                    className="form-input"
                                />
                                {formErrors.registrationNumber && (
                                    <p className="error">{formErrors.registrationNumber}</p>
                                )}
                            </label>
                        </div>
                        <div className="form-group">
                            <label>
                                Presenting Author Name *
                                <input
                                    type="text"
                                    name="authorName"
                                    placeholder="Enter Presenting Author Name"
                                    className="form-input"
                                />
                                {formErrors.authorName && (
                                    <p className="error">{formErrors.authorName}</p>
                                )}
                            </label>
                        </div>
                    </div>
                    <div className="form-row">
                        <div className="form-group">
                            <label>
                                Presenting Author's Phone Number *
                                <input
                                    type="tel"
                                    name="phoneNumber"
                                    placeholder="Enter your phone"
                                    className="form-input"
                                />
                                {formErrors.phoneNumber && (
                                    <p className="error">{formErrors.phoneNumber}</p>
                                )}
                            </label>
                        </div>
                        <div className="form-group">
                            <label>
                                Abstract Title *
                                <input
                                    type="text"
                                    name="abstractTitle"
                                    placeholder="Abstract Title"
                                    className="form-input"
                                />
                                {formErrors.abstractTitle && (
                                    <p className="error">{formErrors.abstractTitle}</p>
                                )}
                            </label>
                        </div>
                    </div>
                    <div className="form-row">
                        <div className="form-group">
                            <label>
                                Select Category *
                                <select name="category" className="form-select">
                                    <option value="">Select</option>
                                    <option value="E-Paper(Doctor)">E-Paper(Doctor)</option>
                                    <option value="E-Paper(Nurse)">E-Paper(Nurse)</option>
                                    <option value="Young Researchers Award">
                                        Young Researchers Award
                                    </option>
                                </select>
                                {formErrors.category && (
                                    <p className="error">{formErrors.category}</p>
                                )}
                            </label>
                        </div>
                    </div>
                    <div className="form-group">
                        <label>
                            Abstract Body (Max up to 200 words) *
                            <textarea
                                name="abstractBody"
                                placeholder="Abstract Body"
                                className="form-textarea"
                                value={abstractBody}
                                onChange={handleWordCount}
                            ></textarea>
                            <p className="word-count">
                                {wordCount} / 200 words
                            </p>
                            {formErrors.abstractBody && (
                                <p className="error">{formErrors.abstractBody}</p>
                            )}
                        </label>
                    </div>
                    <button type="submit" className="submit-btn" disabled={isSubmitting}>
                        {isSubmitting ? "Submitting..." : "Submit Abstract"}
                    </button>
                </form>
            </div>
            <Contact />
        </>
    );
};

export default AbstractForm;
